import "./navbar.css";

export default function Navbar(props) {
  return (
    <div className={props.navbar ? "navbar active" : "navbar"}>
      <ul className="navbarList">
        <li className="home">
          <a className="navbarItem" href="#titleContainer">
            {props.language.navbarItem1}
          </a>
        </li>

        <li>
          <a className="navbarItem" href="#services">
            {props.language.navbarItem2}
          </a>
        </li>
        <li>
          <a className="navbarItem" href="#sectionOneContainer">
            {props.language.navbarItem3}
          </a>
        </li>
        <li>
          <a className="navbarItem" href="#rating">
            {props.language.navbarItem5}
          </a>
        </li>
        <li>
          <a className="navbarItem" href="#footerContainer">
            {props.language.navbarItem4}
          </a>
        </li>
      </ul>
    </div>
  );
}
