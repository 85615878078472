import React, { useState } from "react";
import "./gallery.css";
import gallery1 from "../images/gallery1.jpg";
import gallery2 from "../images/gallery2.jpg";
import gallery3 from "../images/gallery3.jpg";
import gallery4 from "../images/gallery4.jpg";
import gallery5 from "../images/gallery5.jpg";
import gallery6 from "../images/gallery6.jpg";
import gallery7 from "../images/gallery7.jpg";
import gallery8 from "../images/gallery8.jpg";
import gallery9 from "../images/gallery9.jpg";
import gallery10 from "../images/gallery10.jpg";
import video from "../images/video.mp4";

export default function Gallery(props) {
  let [previewType, setPreviewType] = useState(gallery1);

  function updatePreview(source) {
    setPreviewType(source);
  }

  function Preview(props) {
    if (props.previewType === video) {
      return (
        <video
          src={previewType}
          controls
          className="previewVideo"
          loading="lazy"
        ></video>
      );
    }
    return (
      <img src={previewType} alt="" className="previewPhoto" loading="lazy" />
    );
  }

  return (
    <div id="sectionOneContainer">
      <h1 id="sectionHeading">{props.language.section}</h1>
      <div className="galleryContainer">
        <div className="previewContainer">
          <Preview previewType={previewType} />
        </div>
        <div className="photosContainer">
          <img
            className="photo"
            loading="lazy"
            src={gallery1}
            alt=""
            onClick={() => {
              updatePreview(gallery1);
            }}
          />
          <img
            className="photo"
            loading="lazy"
            src={gallery2}
            alt=""
            onClick={() => {
              updatePreview(gallery2);
            }}
          />
          <img
            className="photo"
            loading="lazy"
            src={gallery3}
            alt=""
            onClick={() => {
              updatePreview(gallery3);
            }}
          />
          <img
            className="photo"
            loading="lazy"
            src={gallery4}
            alt=""
            onClick={() => {
              updatePreview(gallery4);
            }}
          />
          <img
            className="photo"
            loading="lazy"
            src={gallery5}
            alt=""
            onClick={() => {
              updatePreview(gallery5);
            }}
          />
          <img
            className="photo"
            loading="lazy"
            src={gallery6}
            alt=""
            onClick={() => {
              updatePreview(gallery6);
            }}
          />
          <img
            className="photo"
            loading="lazy"
            src={gallery7}
            alt=""
            onClick={() => {
              updatePreview(gallery7);
            }}
          />
          <img
            className="photo"
            loading="lazy"
            src={gallery8}
            alt=""
            onClick={() => {
              updatePreview(gallery8);
            }}
          />
          <img
            className="photo"
            loading="lazy"
            src={gallery9}
            alt=""
            onClick={() => {
              updatePreview(gallery9);
            }}
          />
          <img
            className="photo"
            loading="lazy"
            src={gallery10}
            alt=""
            onClick={() => {
              updatePreview(gallery10);
            }}
          />
          <video
            className="video"
            loading="lazy"
            src={video}
            onClick={() => {
              updatePreview(video);
            }}
          ></video>
        </div>
      </div>
    </div>
  );
}
