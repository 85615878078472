import React from "react";
import { SK, EN, ES, FR, RU } from "../language/language";
import slovakFlag from "../images/slovakia.png";
import englishFlag from "../images/UK.png";
import frenchFlag from "../images/french.png";
import russiaFlag from "../images/russia.png";
import spainFlag from "../images/spain.png";
import logo from "../images/LOGOcarNEW.png";
import "./title.css";
import Navbar from "./navbar";
import { BsWhatsapp, BsInstagram } from "react-icons/bs";

export default function Title(props) {
  return (
    <div id="titleContainer">
      <div className="socialMediaContainer">
        <a href="tel:+5353059980" rel="noreferrer">
          <BsWhatsapp className="whatsapp" />
        </a>
        <a
          href="https://www.instagram.com/taxihavana/"
          target="_blank"
          rel="noreferrer"
        >
          <BsInstagram className="instagram" />
        </a>
      </div>
      <div className="logoContainer">
        <img src={logo} alt="null" className="logo" loading="lazy" />
        <h1 className="siteHeading">havanatourcars.com</h1>
      </div>
      <div className="languageButtons">
        <img
          className="englishFlag"
          title="English"
          src={englishFlag}
          alt="EN"
          width="50px"
          onClick={() => props.changeLanguage(EN)}
        />
        <img
          className="russiaFlag"
          title="Russian"
          src={russiaFlag}
          alt="RU"
          width="40px"
          onClick={() => props.changeLanguage(RU)}
        />
        <img
          className="spainFlag"
          title="Spain"
          src={spainFlag}
          alt="ES"
          width="40px"
          onClick={() => props.changeLanguage(ES)}
        />
        <img
          className="frenchFlag"
          title="French"
          src={frenchFlag}
          alt="FR"
          width="40px"
          onClick={() => props.changeLanguage(FR)}
        />
        <img
          className="slovakFlag"
          title="Slovak"
          src={slovakFlag}
          alt="SK"
          width="40px"
          onClick={() => props.changeLanguage(SK)}
        />
      </div>
      <Navbar language={props.language} navbar={props.navbar} />
    </div>
  );
}
